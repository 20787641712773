import React, { createContext, useState, useEffect, useCallback } from "react"
import { getOrders } from "../../../services/apiProduct"

export const SaleManagementContext = createContext({})

const breadcrumbMap = {
  pending: {
    titleDisplay: "すべて",
    breadcrumbDisplay: "",
  },
  toShip: {
    titleDisplay: "発注承認待ち",
    breadcrumbDisplay: "",
  },
  toReceive: {
    titleDisplay: "受注",
    breadcrumbDisplay: "発送準備",
  },
  completed: {
    titleDisplay: "発送完了（納品）",
    breadcrumbDisplay: "発送完了（納品）",
  },
  canceled: {
    titleDisplay: "キャンセル完売処理",
    breadcrumbDisplay: "キャンセル ",
  },
  toReturn: {
    titleDisplay: "返品・返金処理",
    breadcrumbDisplay: "返品・返金処理",
  },
  refund: {
    titleDisplay: "帳簿出力",
    breadcrumbDisplay: "",
  },
}

const totalOrderForEachTabMap = {
  pending: {
    totalOrder: "16",
    title: "受注",
  },
  toShip: {
    totalOrder: "16",
    title: "発送準備",
  },
  toReceive: {
    totalOrder: "16",
    title: "発送完了",
  },
  completed: {
    totalOrder: "16",
    title: "納品完了",
  },
  canceled: {
    totalOrder: "16",
    title: "キャンセル",
  },
  toReturn: {
    totalOrder: "16",
    title: "返品処理中",
  },
  refund: {
    totalOrder: "16",
    title: "返品処理完了",
  },
}

const dataStatus = {
  pending: ["pending"],
  toShip: ["toShip", "prepare"],
  toReceive: ["toReceive"],
  completed: ["completed"],
  cancelled: ["canceled"],
  toReturn: ["toReturn"],
  refund: ["refund"],
}

const getCountTab = (arr, key, statusType) => {
  return arr?.find((item) => item[statusType] === key)?.count
}

export const SaleManagementProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const orderPerPage = 10
  const [keyTab, setKeyTab] = useState("pending")
  const [currentPage, setCurrentPage] = useState(1)
  const [breadcrumb, setBreadcrumb] = useState("pending")
  const [totalOrder, setTotalOrder] = useState(0)
  const [keySearch, setKeySearch] = useState("")
  const [countForTab, setCountForTab] = useState({})
  const [tableData, setTableData] = useState([])
  const [leftLimitDate, setLeftLimitDate] = useState("")
  const [rightLimitDate, setRightLimitDate] = useState("")

  const updateCountTab = useCallback(
    (data) => {
      const countFulfillStatus = data?.countFulfillStatus || []
      const countForTabTemp = {
        "1": data?.total,
        "2": 0,
        "3": data?.total,
        "4": getCountTab(countFulfillStatus, "shipped", "fulfillstatus"),
        "5": getCountTab(countFulfillStatus, "canceled", "fulfillstatus"),
        "6":
          getCountTab(countFulfillStatus, "returned", "fulfillstatus") +
          getCountTab(
            countFulfillStatus,
            "partially_returned",
            "fulfillstatus"
          ),

        "7": 0,
        "8": 0,
      }
      setCountForTab(countForTabTemp)
    },
    [setCountForTab, getCountTab]
  )

  const getTableData = useCallback(
    async (_keyTab) => {
      const params = {
        "created_at[gt]": leftLimitDate,
        "created_at[lt]": rightLimitDate,
        q: keySearch,
        offset: currentPage ? (currentPage - 1) * orderPerPage : 0,
        limit: orderPerPage,
      }
      setLoading(true)

      const response = await getOrders({
        params: {
          ...params,
          status: dataStatus[_keyTab],
        },
      })
      setTableData(response.data?.orders)
      setTotalOrder(response.data?.count)
      updateCountTab(response.data)
      setLoading(false)
    },
    [
      setLoading,
      setTableData,
      setTotalOrder,
      getOrders,
      leftLimitDate,
      rightLimitDate,
      currentPage,
      keySearch,
      updateCountTab,
    ]
  )

  useEffect(() => {
    getTableData(keyTab)
  }, [getTableData, keyTab])

  const resetRetAllState = () => {
    setBreadcrumb("pending")
    setKeyTab("1")
    setCurrentPage("1")
    setLeftLimitDate("")
    setRightLimitDate("")
    setKeySearch("")
  }

  const data = {
    setBreadcrumb,
    currentPage,
    setCurrentPage,
    tableData,
    totalOrder,
    leftLimitDate,
    rightLimitDate,
    setLeftLimitDate,
    setRightLimitDate,
    orderPerPage,
    keySearch,
    setKeySearch,
    setKeyTab,
    keyTab,
    loading,
    setLoading,
    totalOrderForEachTabMap,
    countForTab,
    getCountTab,
    setCountForTab,
    resetRetAllState,
    breadcrumb,
    breadcrumbMap,
  }

  return (
    <SaleManagementContext.Provider value={data}>
      {children}
    </SaleManagementContext.Provider>
  )
}

export default SaleManagementProvider
