import React from "react"
import HeaderRow from "../../salesManagementy/HeaderRow"
import DataTable from "./DataTable"

const TableContact = () => {
  return (
    <div>
      <HeaderRow />
      <DataTable />
    </div>
  )
}

export default TableContact
