import { Button, Col, DatePicker, Input, Row, Checkbox } from "antd"
import React, { useContext, useState } from "react"
import { SaleManagementContext } from "./saleManagementContext/SaleManagementContext"
import moment from "moment"
import { navigate } from "gatsby"
const HeaderRow = () => {
  const {
    setLeftLimitDate,
    setRightLimitDate,
    setKeySearch,
    keyTab,
  } = useContext(SaleManagementContext)

  const onChangeLeftDate = (date, dateString) => {
    setLeftLimitDate(moment(date).toISOString())
  }
  const onChangeRightDate = (date, dateString) => {
    if (dateString === "") {
      const nowString = moment(new Date()).format("YYYY/MM/DD 00:00")
      const targetDate = moment(nowString).add(1, "days")
      setRightLimitDate(moment(targetDate).toISOString())
      return
    }
    const temp = moment(date).add(1, "days").format("YYYY/MM/DD 00:00")
    const targetDate = new Date(temp)
    console.log(targetDate)
    setRightLimitDate(targetDate.toISOString())
  }

  const [inputKey, setInputKey] = useState("")

  const onChangeKeySearch = (event) => {
    setInputKey(event.target.value)
  }

  // console.log(tableData)
  return (
    <div className="bg-slate-100 p-4 text-black-0">
      <Row className="p-4">絞り込み条件</Row>
      <Row
        className="p-4"
        gutter={[
          { xxl: 5, xl: 10, lg: 10, md: 10, sm: 10, xs: 10 },
          { xxl: 5, xl: 10, lg: 10, md: 10, sm: 10, xs: 10 },
        ]}
      >
        {(keyTab === "7" || keyTab === "8") && (
          <>
            <Col xxl={2} xl={3} lg={4} md={4} sm={7} xs={12}>
              <div className="flex items-center w-full h-full gap-2 px-2">
                <Checkbox
                  style={{
                    width: "100%",
                  }}
                >
                  <span className="block w-full min-w-max">受注毎</span>
                </Checkbox>
              </div>
            </Col>
            <Col xxl={2} xl={3} lg={4} md={4} sm={7} xs={12}>
              <div className="flex items-center w-full h-full gap-2 px-2">
                <Checkbox style={{ width: "100%" }}>
                  <span className="block w-full min-w-max">受注月毎</span>
                </Checkbox>
              </div>
            </Col>
          </>
        )}
        <Col xxl={5} xl={9} lg={8} md={8} sm={12} xs={24}>
          <div className="flex items-center w-full h-full">
            <DatePicker
              format={"YYYY年MM月DD日"}
              style={{ width: "80%" }}
              placeholder="2022年1月"
              onChange={onChangeLeftDate}
            />
            <span className="mx-[5px] block w-1/5">から</span>
          </div>
        </Col>
        <Col xxl={5} xl={9} lg={8} md={8} sm={12} xs={24}>
          <div className="flex items-center w-full h-full">
            <DatePicker
              format={"YYYY年MM月DD日"}
              style={{ width: "80%" }}
              placeholder="2022年2月"
              onChange={onChangeRightDate}
            />
            <span className="mx-[5px] block w-1/5">まで</span>
          </div>
        </Col>
        <Col xxl={8} xl={21} lg={21} md={21} sm={21} xs={18}>
          <div className="flex items-center w-full h-full">
            <Input
              style={{ width: "100%" }}
              placeholder="キーワードで探す"
              onChange={onChangeKeySearch}
            />
          </div>
        </Col>
        <Col xxl={2} xl={3} lg={3} md={3} sm={3} xs={6}>
          <div className="flex items-center w-full h-full">
            <Button
              style={{
                width: "100%",
                marginLeft: 20,
                backgroundColor: "#EC4D43",
                border: 0,
                letterSpacing: "0px !important",
              }}
              type="primary"
              onClick={(event) => {
                event.preventDefault()
                setKeySearch(inputKey)
              }}
            >
              <span>検</span>
              <span>索</span>
            </Button>
          </div>
        </Col>
      </Row>
      {keyTab === "8" && (
        <Row className="p-4">
          <Button
            style={{
              color: "white",
              backgroundColor: "#ECB427",
              minWidth: "200px",
            }}
            onClick={() => {
              navigate(`/sales-management/detail?keyTab=${"8"}`)
            }}
          >
            ＋ 新規見積書作成
          </Button>
        </Row>
      )}
    </div>
  )
}

export default HeaderRow
